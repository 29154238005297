<template>
	<div class="ns-seckill" style="    background: #ffffff00;">
		<el-carousel height="395px" v-loading="loadingAd" v-if="adList.length">
			<el-carousel-item v-for="item in adList" :key="item.adv_id"><el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" /></el-carousel-item>
		</el-carousel>
		
		<div class="ns-seckill-time-wrap" v-if="timeList.length > 0" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;     background-image: linear-gradient(to right, #555555 0%, #202020 100%);">
			<div class="ns-seckill-time-box">
				<span class="left-btn iconfont iconarrow-left-copy" @click="changeThumbImg('prev')"></span>
				<span class="right-btn iconfont iconarrow-right" @click="changeThumbImg('next')"></span>
				<div class="ns-seckill-time-list" ref="seckillTime">
					<ul class="seckill-time-ul" :style="{ left: thumbPosition + 'px' }">
						<!-- รูปขนาดย่อของผลิตภัณฑ์ -->
						<li class="seckill-time-li" v-for="(item, key) in timeList" :key="key" @click="handleSelected(key, item)">
							<div slot="label" class="tab-li" :class="{ 'selected-tab': seckillId == item.id }">
								<div style="font-family: 'sukhumvittadmai2'; font-size: 15px; ">{{ item.name }}</div>
								<div>
									<p  style="color: #ffffff; font-family: 'sukhumvittadmai2';" v-if="key > index">กำลังจะมา</p>
									<p style="color: #ffffff; font-family: 'sukhumvittadmai2';" v-if="key == index && !item.isNow">เร็ว ๆ นี้</p>
									<p  style="color: #ffffff; font-family: 'sukhumvittadmai2';" v-if="key == index && item.isNow">กำลังลดอยู่</p>
									<p  style="color: #ffffff; font-family: 'sukhumvittadmai2';" v-if="key < index">หมดอายุ</p>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>

		

		<!-- รายการผลิตภัณฑ์ -->
		<div class="ns-seckill-box" ref="seckillGoods" v-if="timeList.length > 0 && goodsList.length > 0">
			<div class="ns-seckill-title">
				<div>
					<i class="iconfont iconmiaosha1"></i>
					<span  style=" font-family: 'sukhumvittadmai2';" >{{ seckillName ? seckillName : 'คอยติดตาม' }}</span>
				</div>
				<div class="ns-seckill-end" v-if="seckillIndex == index && isTrue && isNoClick == false" style=" font-family: 'sukhumvittadmai2';">
					เวลาหมดกิจกรรม
					<count-down style="padding-left:10px;"
						class="count-down"
						v-on:start_callback="countDownS_cb()"
						v-on:end_callback="countDownE_cb()"
						:currentTime="seckillTimeMachine.currentTime"
						:startTime="seckillTimeMachine.startTime"
						:endTime="seckillTimeMachine.endTime"
						:dayTxt="'：'"
						:hourTxt="'：'"
						:minutesTxt="'：'"
						:secondsTxt="''"
					></count-down>
				</div>
			</div>

			<div v-loading="loading">
				<div class="goods-list">
					<div class="item" v-for="(item, key) in goodsList" :key="key">
						
						
						
						
						<div id="" class="css-y5gcsw" @click="toGoodsDetail(item.id)" >
						  <div class="css-5fmc3z" data-testid="divProductWrapper" data-action="false">
						    <div class="css-qa82pd" data-testid="master-product-card">
						      <div class="prd_container-card css-1c4umxf" data-testid="" style="    border: 1px solid rgb(224, 224, 224);">
						        <div class="pcv3__container css-gfx8z3">
						          <div class="css-1f2quy8">
						            <a>
						              <div class="responsive css-1eg7f6s">
						                <span class="responsive css-10rucli"></span>
						                <img  class="css-1c345mg"  :src="$img(item.goods_image)" >
										
						              </div>
						            </a>
						          </div>
						          <div class="css-974ipl">
						            <a class="pcv3__info-content css-gwkf0u" >
						              <div class="prd_link-product-name css-svipq6" data-testid="linkProductName">{{ item.goods_name }}</div>
						              <div class="">
						                <div class="prd_link-product-price css-1ksb19c" data-testid="linkProductPrice" style="font-size: 22px;">฿{{ item.seckill_price }}</div>
						                <div class="css-hwbdeb">
						                  <div class="prd_badge-product-discount css-1qtulwh" data-testid="lblProductDiscount">100%</div>
						                  <div class="prd_label-product-slash-price css-1u1z2kp" data-testid="lblProductSlashPrice">฿ {{ item.price }}</div>
						                </div>
						              </div>
						              
						             
						            </a>
						          </div>
						        </div>
						      </div>
						    </div>
						  </div>
						</div>
						
						
					</div>
				</div>
			</div>
			
			<div class="pager">
				<el-pagination
					background
					:pager-count="5"
					:total="total"
					prev-text="ก่อน"
					next-text="ต่อไป"
					:current-page.sync="currentPage"
					:page-size.sync="pageSize"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange"
					hide-on-single-page
				></el-pagination>
			</div>
			
		
			
		</div>
		
		<div v-if="!timeList || !goodsList" class="empty-wrap"><div class="ns-text-align"><router-link to="/">ไม่มีข้อเสนอใด ๆ ที่อยู่ระหว่างดําเนินการ，ไปที่หน้าแรกและดู</router-link></div></div>
	</div>
</template>

<script>
    import list from "./list.js"
    export default {
        name: "seckill",
        components: {},
        mixins: [list]
    }
</script>
<style lang="scss" scoped>
    @import "./list.scss";
</style>

<style lang="scss">
.seckill-time {
	.el-tabs__nav-wrap {
		height: 56px;

		.el-tabs__nav {
			height: 56px;
		}

		.el-tabs__nav-next,
		.el-tabs__nav-prev {
			line-height: 56px;
		}

		.el-tabs__item {
			width: 150px;
			height: 56px;
			padding: 0;
		}
	}
	.el-tabs__nav-wrap::after {
		height: 0;
	}
}
.ns-seckill {
	.el-carousel {
		.el-image__inner {
			width: auto;
		}
	}
	.el-carousel__arrow--right{
		right: 60px;
	}

	.count-down {
		span {
			display: inline-block;
			width: 24px;
			height: 24px;
			line-height: 24px;
			text-align: center;
			background: #383838;
			color: #ffffff;
			border-radius: 2px;
		}
	}
}.ns-seckill .ns-seckill-time-box ul li .tab-li{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 0;
    color: #afafaf;
}/*! CSS Used from: Embedded */
a{background-color:transparent;-webkit-text-decoration-skip:objects;}
img{border-style:none;}
/*! CSS Used from: Embedded */
:focus{outline:0;}
*,::after,::before{box-sizing:inherit;}
::-webkit-input-placeholder{color:rgba(0,0,0,.26);}
::-moz-placeholder{color:rgba(0,0,0,.26);}
:-ms-input-placeholder{color:rgba(0,0,0,.26);}
:-moz-placeholder{color:rgba(0,0,0,.26);}
a{color:rgba(0,0,0,.7);text-decoration:none;}
/*! CSS Used from: Embedded */
.css-y5gcsw{position:relative;background-color:var(--N0,#FFFFFF00);display:inline-flex;-webkit-box-align:stretch;align-items:stretch;width:100%;height:100%;padding:0px;box-sizing:border-box;}
.css-qa82pd{position:relative;flex:0.5 1 0%;z-index:0;height:100%;min-width:50%;cursor:pointer;}
.css-qa82pd:hover .flip{transform:translateY(-18px);}
.css-1c4umxf{display:flex;flex-direction:column;-webkit-box-pack:justify;justify-content:space-between;height:100%;box-shadow:rgba(0, 0, 0, 0.05) 0px 1px 6px 0px;border-radius:15px;overflow:hidden;padding:0px;margin:0px;}
.css-yaxhi2{position:relative;}
.css-yaxhi2 > div:last-child{margin-bottom:1px;width:85%;}
@media (max-width: 374px){
.css-yaxhi2 > div:last-child{width:100%;}
}
.css-yaxhi2.full > div:last-child{width:100%;}
.css-hwbdeb{display:flex;-webkit-box-align:center;align-items:center;margin-bottom:4px;}
.css-1qtulwh{display:flex;-webkit-box-pack:center;justify-content:center;-webkit-box-align:center;align-items:center;flex:0 0 30px;height:20px;color:var(--RN500,#F94D63);background-color:var(--RN100,#FFDBE2);font-size:0.714286rem;font-weight:bold;border-radius:3px;margin:0px 4px 0px 0px;}
.css-1u1z2kp{font-size:0.714286rem;color:var(--NN600,#6D7588);text-decoration:line-through;max-width:100px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}
.css-1c345mg{position:absolute;inset:0px;width:0px;height:0px;min-width:100%;max-width:100%;min-height:100%;max-height:100%;display:block;padding:0px;margin:auto;border:none;box-sizing:border-box;}
.css-gwkf0u{display:flex;flex-flow:column;-webkit-box-flex:1;flex-grow:1;text-align:left;}
.css-svipq6{color:var(--NN950,#212121);font-size:0.86rem;line-height:1.5;max-width:100%;max-height:100%;white-space:pre-wrap;word-break:keep-all;text-overflow:ellipsis;overflow:hidden;margin-bottom:4px;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;}
.css-1ksb19c{display:flex;-webkit-box-pack:justify;justify-content:space-between;-webkit-box-align:center;color:var(--NN950,#212121);opacity:1;font-size:1rem;font-weight:bold;align-items:flex-end;line-height:1.43;margin-bottom:4px;}
.css-1rn0irl{display:flex;flex-direction:column;line-height:18px;height:20px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;width:100%;}
.css-1kdc32b{height:20px;transition:transform 0.3s ease 0s;transform:translateY(0px);color:var(--NN600,#6D7588);}
.css-1ktbh56{display:flex;-webkit-box-align:center;align-items:center;color:var(--N700,rgba(49,53,59,0.54));font-size:0.85rem;line-height:18px;margin-bottom:4px;}
.css-7nqkuh{position:relative;margin-top:auto;}
.css-1oh0m8z{flex:0 1 20%;display:inline-flex;max-width:20%;padding:0px 0.8% 1.6%;box-sizing:border-box;}
.css-5fmc3z{position:relative;border-radius:8px;min-width:100%;}
@media (max-width: 1119px){
.css-5fmc3z [data-productinfo] > div:last-child{width:100%;}
}
.css-1f2quy8{position:relative;display:block;margin:0px;height:0px;padding-bottom:100%;overflow:hidden;}
.css-1f2quy8 a{display:initial;}
.css-1f2quy8 img{border-radius:8px 8px 0px 0px;}
.css-gfx8z3{display:flex;position:static;overflow:visible;background-color:var(--N0,#FFFFFF);flex-flow:column nowrap;height:100%;}
.css-10rucli{display:block;width:initial;height:initial;opacity:1;border:0px;margin:0px;padding:100% 0px 0px;background:none;box-sizing:border-box;overflow:hidden;}
.css-10rucli.responsive{display:block;}
.css-1eg7f6s{position:relative;opacity:1;border:0px;margin:0px;padding:0px;height:initial;background:none;overflow:hidden;box-sizing:border-box;display:block;width:100%;}
.css-1eg7f6s.responsive{display:block;}
.css-1eg7f6s > img{display:block;object-fit:cover;object-position:center center;width:100%;height:100%;margin:0px auto;}
.css-974ipl{position:relative;display:flex;flex:1 1 0%;flex-direction:column;vertical-align:middle;height:auto;width:100%;padding:8px;overflow:hidden;background-color:var(--N0,#FFFFFF);}
.css-1y28ufk{position:relative;display:flex;flex:0 0 16px;height:16px;margin-right:2px;background-position:center center;background-repeat:no-repeat;background-size:contain;background-image:url(https://images.tokopedia.net/img/goldmerchant/pm_activation/badge/Power%20Merchant%20Pro.png);}
@media (min-width: 1024px){
.css-1y28ufk{margin-right:4px;}
}
.css-13sxdm3{position:relative;height:4px;width:100%;border-radius:8px;margin-top:8px;overflow:hidden;background:var(--N75,#E5E7E9);}
.css-13sxdm3 .progress{background-color:var(--R500,#EF144A);position:absolute;height:4px;width:76%;top:0px;left:0px;border-radius:8px;}
.css-8p3yba{height:16px;background-color:var(--N0,#FFFFFF);border-radius:3px;font-weight:bold;font-size:0.714286rem;color:rgb(49, 53, 59);line-height:1.4;letter-spacing:normal;margin-bottom:4px;padding:3px 0px 2px;position:relative;display:block;}

.ns-seckill .ns-seckill-time-box ul li .tab-li:hover, .ns-seckill .ns-seckill-time-box ul li .selected-tab{
    background-color: #ff5001;
    color: #FFFFFF;
}/*! CSS Used from: https://s2.konvy.com/static/min/css/j/j.v4.85.min.css?v=1626429966 ; media=screen */
@media screen{
div{margin:0;padding:0;}
img{border:0;}
a{color:#f73173;text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/index.min.css?v=1654680463 ; media=screen */
@media screen{
div{margin:0;padding:0;}
img{border:0;}
a{text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/utils/css/ky.min.css?v=1664420402 */
*{padding:0;margin:0;box-sizing:content-box;}
img{border:none;}
a:hover,a{text-decoration:none;}
a{color:#333;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/admin/adminbrand_flagship.min.css?v=1665338270 */
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
a{text-decoration:none;color:#333;}
img{vertical-align:middle;}
.moduleWarp{overflow:hidden;}
.in-Full-Screen{display:none;}
.in-Full-Screen a{display:block;text-align:center;}
.in-Full-Screen a>img{max-width:100%;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/web/promo_new.min.css?v=1661745912 */
.moduleWarp{display:none;}/*! CSS Used from: https://www.mercular.com/globals.css */
a{color:inherit;text-decoration:none;}
*{box-sizing:border-box;scrollbar-color:#9E9E9E transparent;scrollbar-width:none;}
html ::-webkit-scrollbar{width:8px!important;}
:not(html)::-webkit-scrollbar{width:6px;}
::-webkit-scrollbar-track{background:transparent;}
::-webkit-scrollbar-thumb{background-color:#9E9E9E;border-radius:8px;}
::-webkit-scrollbar-thumb:hover{background-color:#9E9E9E;transition:background-color .3s;}
/*! CSS Used from: Embedded */
*,*::before,*::after{box-sizing:inherit;}
/*! CSS Used from: Embedded */
@media (max-width:899.95px){
.css-9n8416{display:none;}
}
@media (min-width:900px){
.css-1m4n54b{display:none;}
}
.css-aq6gyo{box-sizing:border-box;margin:0;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;max-width:100%;}
@media (min-width:600px){
.css-aq6gyo{-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;max-width:100%;}
}
@media (min-width:900px){
.css-aq6gyo{-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;max-width:100%;}
}
@media (min-width:1280px){
.css-aq6gyo{-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;max-width:100%;}
}
@media (min-width:1440px){
.css-aq6gyo{-webkit-flex-basis:100%;-ms-flex-preferred-size:100%;flex-basis:100%;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;max-width:100%;}
}
@media (min-width:0px){
.css-1tt8f38>.MuiGrid-item{padding-top:8px;}
}
@media (min-width:1280px){
.css-1tt8f38>.MuiGrid-item{padding-top:16px;}
}
@media (min-width:0px){
.css-1tt8f38>.MuiGrid-item{padding-left:8px;}
}
@media (min-width:1280px){
.css-1tt8f38>.MuiGrid-item{padding-left:16px;}
}
.css-1o20qu2{max-height:145px;border-radius:8px;display:block;object-fit:cover;width:100%;}
.css-1ccu3b5{width:100%;max-height:145px;border-radius:8px;display:block;object-fit:contain;}

.ns-seckill .ns-seckill-time-box ul li .tab-li:hover, .ns-seckill .ns-seckill-time-box ul li .selected-tab {
    background-color: #ff5000;
    color: #FFFFFF;
}
.ns-seckill .ns-seckill-box .ns-seckill-title span{
 
    color: #ff5100;

}.ns-seckill .ns-seckill-box .ns-seckill-title{
   
    border-bottom: 1px solid #ff5000;
  
}.ns-seckill .ns-seckill-box .ns-seckill-title span {
    color: #ffffff;
}
</style>